/**
 * Функция для разбиения числа на разряды
 * @param x
 * @returns {string}
 */
/*
const formatPrice = x => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace(',', '.')
  } else {
    return '0'
  }
}
*/

export function enteringAmount (amount, decimal = 5) {
  let numLine = amount
  if (numLine[0] === '.' || numLine[0] === ',') {
    amount = '0.'
    numLine = 0
  }
  if (numLine[0] === '0' && numLine[1] === '0' && numLine[1] !== '.') {
    amount = numLine[0].replace('0', '')
    numLine = 0
  }
  if (numLine) {
    numLine = numLine.replace(',', '.')
    if (numLine.split('.').length > 1) {
      // eslint-disable-next-line
      amount = numLine.split('.')[0].replace(/[^.\d]+/g, '').replace(/^([^\.]*\.)|\./g, '$1') + '.' + numLine.split('.')[1].replace(/[^.\d]+/g, '')
      let splitElement = amount.split('.')
      if (splitElement.length > 1) {
        if (splitElement[1].length === decimal) {
          if (decimal === 0) {
            amount = splitElement[0]
          }
          if (splitElement[1][4] === '0') {
            amount = splitElement[0] + '.' + splitElement[1].slice(0, -1)
          }
        }
        if (splitElement[1].length > decimal) {
          amount = splitElement[0] + '.' + splitElement[1].slice(0, -1)
        }
      }
    } else {
      // eslint-disable-next-line
      amount = numLine.replace(/[^.\d]+/g, '').replace(/^([^\.]*\.)|\./g, '$1')
    }
  }
  return amount
}

export function getFloatingNumber(str, num = 0) {
  // const regex = new RegExp(`[0-9]+\\.[0-9]{0,${num}}`);
  // const regex = new RegExp(`^\d+(\\.\d{1,${num}})?$`)
  const regex = new RegExp(`^[0-9]*[.,]?[0-9]{0,${num}}$`)
  const match = str.match(regex);

  return match?.[0] || str.slice(0, str.length - 1);
}

export default {
  install (Vue) {
    // console.log('Entering data plugin')
    Vue.config.globalProperties.$EnteringAmount = enteringAmount
    Vue.config.globalProperties.$EnteringUser = function (userName) {
      console.log(userName.replace(' ', '').toLowerCase())
      return userName.replace(' ', '').toLowerCase()
    }
    Vue.config.globalProperties.$FormatPrice = function (data) {
      if (data) {
        let temp = String(data).split('.')
        if (temp.length > 1) {
          return temp[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace(',', '.') + '.' + temp[1]
        } else {
          return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace(',', '.')
        }
      } else {
        return '0'
      }
    }
    Vue.config.globalProperties.$UserId = function (data) {
      // eslint-disable-next-line
      data = data.replace(/[^\d]/g, '')
      if (data.length > 12) {
        data = data.substring(0, data.length - 1)
      }
      return data
    }
    Vue.config.globalProperties.$FormatAmount = function (amount, precision) {
      if (!amount) return ''
      amount = String(amount)
      precision = Number(precision)
      amount = amount.replace(/[^0-9.,]/g, '')
        .replace(/^0(0+)$/, '0.$1')
        .replace(/[.,]/, 'X')
        .replace(/[.,]/g, '')
        .replace(/[X]/, '.')
        .replace(/^0+(0\..*|[1-9].*)/, '$1')
        .replace(/^\./, '0.')
        .replace(new RegExp(`(\\d*\\.\\d{0,${precision}}).*`), '$1')
      return amount
    }
  }
}
