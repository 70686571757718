/* eslint-disable */
let SetCookie = (name, value, options) => {
  options = {
    path: '/',
    secure: true,
    'max-age': 31536000,
    // при необходимости добавьте другие значения по умолчанию
    ...options
  }

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString()
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value)

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey
    let optionValue = options[optionKey]
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue
    }
  }

  document.cookie = updatedCookie
}

export default {
  install (Vue) {
    // console.log('Cookie plugin work')
    Vue.config.globalProperties.$GetCookie = function (data) {
      let matches = document.cookie.match(new RegExp("(?:^|; )" + data.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
      return matches ? decodeURIComponent(matches[1]) : undefined
    }
    Vue.config.globalProperties.$SetCookie = function (name, value, options = {}) {
      options = {
        path: '/',
        secure: true,
        'max-age': 31536000,
        // при необходимости добавьте другие значения по умолчанию
        ...options
      }

      if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString()
      }

      let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value)

      for (let optionKey in options) {
        updatedCookie += "; " + optionKey
        let optionValue = options[optionKey]
        if (optionValue !== true) {
          updatedCookie += "=" + optionValue
        }
      }

      document.cookie = updatedCookie
    }
    Vue.config.globalProperties.$DelCookie = function (name) {
      SetCookie(name, "", {
        'max-age': -1
      })
    }
  }
}
/* eslint-disable */
