import Decimal from 'decimal.js'

/**
 * Функция получения целого числа из числа с плавающей точкой
 * @param number - число для перевода
 * @param exponential - количество знаков после запятой
 * @returns {number} - целое число
 */
const toInteger = (number, exponential) => {
  number = Number(number)
  exponential = Number(exponential)
  let a = '1'
  for (let i = 0; i < exponential; i++) {
    a += '0'
  }
  return Math.round(number * Number(a))
}

/**
 * Функция для возврата строки из экспоненциального числа
 * @param digits - число в экспоненциальном виде
 * @param amountScale - максимальное значение ограничения числа после запятой
 * @returns {*|string} - число в "человеческом" виде в виде строки
 */
const exportExponentialToString = (digits, amountScale) => {
  let exportString = ''

  let str = String(digits)
  let order = str.replace(/.+e-/gm, '')
  // eslint-disable-next-line
  let mantis = str.match(/(\d*[\.\,]?\d+?)e/)
  let mantisLength = 0
  if (mantis[1].indexOf('.') !== -1) {
    let mantisArray = mantis[1].split('.')
    mantisLength = mantisArray[1].length
  }
  let totalExponentialDigits = Number(order) + Number(mantisLength)
  if (totalExponentialDigits < amountScale) {
    exportString = digits.toFixed(totalExponentialDigits)
  } else {
    exportString = digits.toFixed(amountScale)
  }
  return exportString
}

/**
 * Функция перевода строки с экспоненциальной записью в обычныю
 * @param amount
 * @returns {string}
 */
const convertAmount = amount => {
  let str = String(Number(amount))
  if (str.indexOf('e') !== -1) {
    let numb = str.replace(/.+e-/gm, '')
    amount = exportExponentialToString(Number(amount), Number(numb))
  }
  return String(amount)
}

export default {
  install (Vue) {
    // console.log('digit plugin run')
    /**
     * Функция расчета стоимости операции (стоимость комиссии)
     * @param a {number} - сумма операции
     * @param b {number} - комиссия
     * @returns {number} - стоимость операции
     */
    Vue.config.globalProperties.$CalculateCommissionlAmount = function (a, b) {
      let aE = 0
      if (a.toString().indexOf('e') !== -1) {
        aE = Number(a.toString().replace(/.+e-/gm, ''))
      } else {
        aE = a.toString().split('.')
        if (aE[1]) {
          aE = aE[1].length
        } else {
          aE = 0
        }
      }
      let bE = 0
      if (b.toString().indexOf('e') !== -1) {
        bE = Number(b.toString().replace(/.+e-/gm, ''))
      } else {
        bE = b.toString().split('.')
        if (bE[1]) {
          bE = bE[1].length
        } else {
          bE = 0
        }
      }
      let aInt = toInteger(a, aE)
      let bInt = toInteger(b, bE)
      return convertAmount((aInt * bInt) / toInteger(1, 2 + aE + bE))
    }

    Vue.config.globalProperties.$GetCommissionAmount = function (amount, commission) {
      let a = new Decimal(amount)
      if (!(commission.hasOwnProperty('commissionCryptoenter') && commission.hasOwnProperty('commissionPercentage'))) {
        throw String('commission object must have commissionCryptoenter & commissionPercentage properties')
      }
      let c = new Decimal(commission.commissionCryptoenter)
        .plus(commission.commissionPercentage)
        .div(100)
      if (a.isNaN() || c.isNaN() || c.lt(0)) {
        throw String('a & c must be numbers; c must be GE 0; c: ' + c.toString() + ' a: ' + a.toString())
      }
      return a.mul(c).toString()
    }

    /**
     * Функция расчета итоговой стоимости
     * @param amount {string} - Объем отдаваемой валюты
     * @param commission {string} - Объем комиссии
     * @retruns {string} - Итоговая стоимость
     */
    Vue.config.globalProperties.$CalculateTotalAmount = function (amount, commission) {
      let itog = new Decimal(amount)
      itog = itog.plus(commission)
      return convertAmount(itog.toNumber())
    }

    Vue.config.globalProperties.$ConverAmount = function (amount) {
      return convertAmount(amount)
    }

    Vue.config.globalProperties.$RoundAmount = function (amount, scale) {
      amount = String(amount)
      if (amount.split('.')[1]) {
        if (amount.split('.')[1].length > scale) {
          let str = Number(amount).toFixed(scale)
          return str.replace(/\.?0+$/, '') // eslint-disable-line
        } else {
          return amount
        }
      } else {
        return amount
      }
    }
  }
}
