import { notify } from '@kyvg/vue3-notification'

export default {
  install (Vue) {
    Vue.config.globalProperties.$CopyToClipboard = function (text) {
      navigator.clipboard.writeText(text).then(
        function () {
          notify({
            title: window.$t('common_success'),
            text: Vue.config.globalProperties.$selectMessageText('En', 'copiedSuccessfully'),
            type: 'success'
          })
        },
        function () {
          notify({
            title: window.$t('error_title'),
            text: Vue.config.globalProperties.$selectMessageText('En', 'copyFailed'),
            type: 'error'
          })
        }
      )
    }
  }
}
