import { notify } from '@kyvg/vue3-notification'

export default {
  install (Vue) {
    // console.log('Copy data plugin')
    Vue.config.globalProperties.$CopyData = function (element) {
      let range = document.createRange()
      range.selectNode(element)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      try {
        let succesful = document.execCommand('copy')
        if (succesful) {
          window.getSelection().removeAllRanges()
          notify({
            title: window.$t('common_success'),
            text: this.$selectMessageText('En', 'copiedSuccessfully'),
            type: 'success'
          })
          return true
        } else {
          window.getSelection().removeAllRanges()
          notify({
            title: window.$t('error_title'),
            text: this.$selectMessageText('En', 'copyFailed'),
            type: 'error'
          })
          return false
        }
      } catch (err) {
        window.getSelection().removeAllRanges()
        notify({
          title: window.$t('error_title'),
          text: this.$selectMessageText('En', 'copyFailed'),
          type: 'error'
        })
        return false
      }
    }
  }
}
